import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import useNavigate
import "./Footer.css";


import pizzalogo from "../../assets/Images/Home/PizzaLogo.png";
import apple from "../../assets/Images/Download/apple.png";
import google from "../../assets/Images/Download/google.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTiktok
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const [tooltip, setTooltip] = useState({ show: false, message: "", x: 0, y: 0 });
  const [isMobile, setIsMobile] = useState(false);


  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const currentYear = new Date().getFullYear();

  return (
    <div>
      <div className="footer-top-margin"></div>
    <div className="footer" >
      
      {!isMobile ? (
        <div className="footer-content">
          <div className="footer-main-log">
            <div className="footer-logo">
              <img src={pizzalogo} alt="" />
              <h3>Pizza Da Valter</h3>
            </div>
          </div>
          <div className="footer-contect">
            <h4>Contact Us</h4>
            <p>
              Phone: +44 20 8355 7032
            </p >
            <p >Email : info@pizzadavalter.co.uk</p>
            <p >Address : 7 Bellevue Road, London, SW17 7EG</p>
          </div>
          <div className="footer-quick-links">
            <h4>Quick Links</h4>

            <Link to="/about">
              <p>About Us</p>
              </Link>
            {/* <Link to="/menu">
              <p>Menu</p>
            </Link> */}
            {/* <Link to="/special">
              <p>Special Diets</p>
            </Link> */}
            <Link to="/service">
              <p>Service</p>
            </Link>
            <Link to="/location">
              {" "}
              <p>Location</p>
            </Link>
          </div>
          <div className="footer-ligal">
            <h4>Legal Information</h4>

            <p >  <Link to="/PrivacyPolicy">Privacy policy</Link></p>
            {/* <p><a>Terms of service</a><p> */}
            <p><a href="https://ratings.food.gov.uk/business/1572108" target="_blank">Allergen information</a></p>
          </div>
          <div className="footer-social-media">
            <h4>Social Media</h4>
            <p>
            <a
                href=" https://www.instagram.com/explore/locations/278152442663307/pizza-da-valter-london/"
                target="_blank"
                rel="noopener noreferrer"
              >
                 Instagram
              </a>
    
            </p>
            {/* <p>
            <a
                href=" https://www.facebook.com/pizzadavalter"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
            </p> */}
            <p>
              <a
                href=" https://www.tiktok.com/@pizzadavalter?_t=8qYuz15cy3s&_r=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                Tiktok
              </a>
            </p>

 
          </div>

          <div className="footer-download">
            <h4>Download Our App</h4>
            <div className="footer-download-icon">
              <img src={apple} alt="" />
              <img src={google} alt="" />
            </div>
          </div>

          {/* mobile */}
        </div>
      ) : (
        <div className="mobile-footer">
          <div className="mobile-footer-row">
            <div className="mobile-footer-main-log">
              <div className="mobile-footer-logo">
                <img src={pizzalogo} alt="" />
                <h3>Pizza Da Valter</h3>
              </div>
            </div>
            <div className="footer-social-icon">
            {/* <a
                href=" https://www.facebook.com/pizzadavalter"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <FontAwesomeIcon icon={faFacebook} />
              </a> */}
              <a
                href=" https://www.tiktok.com/@pizzadavalter?_t=8qYuz15cy3s&_r=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <FontAwesomeIcon icon={faTiktok} />
              </a>
              <a
                href="https://www.instagram.com/explore/locations/278152442663307/pizza-da-valter-london/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </div>
          </div>
          <div className="mobile-footer-row">
            <div className="footer-quick-links">
              <h4>Quick Links</h4>

            
              <Link to="/about">
              <p>About Us</p>
              </Link>
              {/* <Link to="/menu">
                <p>Menu</p>
              </Link> */}
              {/* <Link to="/special">
                <p>Special Diets</p>
              </Link> */}
              <Link to="/service">
                <p>Service</p>
              </Link>
              <Link to="/location">
                {" "}
                <p>Location</p>
              </Link>
            </div>
            <div className="footer-contect">
              <h4>Contact Us</h4>
              <p><a>
                Phone:{" "}
                <a href="tel:+442083557032" style={{ textDecoration: "none", color: "inherit" }}>
                  +44 20 8355 7032
                </a>
                </a></p>
              <p> <a>Email : info@pizzadavalter.co.uk </a></p>
              <p> <a>
                Address : 7 Bellevue Road, <br />
                London, SW17 7EG
                </a></p>
            </div>
          </div>
          <div className="mobile-footer-row">
            <div className="footer-ligal">
              <h4>Legal Information</h4>
              <p >  <Link to="/PrivacyPolicy">Privacy policy</Link></p>
              {/* <p><a>Terms of service</a></p> */}
              <p><a href="https://ratings.food.gov.uk/business/1572108" target="_blank">Allergen information</a></p>
            </div>
            <div className="footer-social-media">
              <h4>Social Media</h4>

              
            <p>
            
            <a
                href="https://www.instagram.com/explore/locations/278152442663307/pizza-da-valter-london/"
                target="_blank"
                rel="noopener noreferrer"
              >
                 Instagram
              </a>
             
            </p>
            <p>
            {/* <a
                href=" https://www.facebook.com/pizzadavalter"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a> */}
            </p>
            <p>
            <a
                href=" https://www.tiktok.com/@pizzadavalter?_t=8qYuz15cy3s&_r=1"
                target="_blank"
                rel="noopener noreferrer"
              >
             
                Tiktok
            </a>
            </p>
            </div>
          </div>
          <div className="mobile-footer-row">
            <div className="footer-download">
              <h4>Download Our App</h4>
              <div className="footer-download-icon">
                <img src={apple} alt="" />
                <img src={google} alt="" />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="footer-bottom">
        <p>© {currentYear} Pizza Da Valter. All Rights Reserved.</p>
      </div>
    </div>
    </div>
  );
};

export default Footer;
